.course-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  background-color: #f0f0f0;
  max-height: 100%;
  overflow-y: scroll;
}

.section-card {
  margin: 1.5rem;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0px 5px 5px 2px rgba(204, 204, 204, 1);
  background-color: white;
  height: fit-content;
}

.lesson-title {
  font-weight: bold;
  font-size: large;
  color: #006080;
  text-align: left;
}

.lesson-description {
  text-align: left;
}

.lesson-content {
  width: 75vw;
}

.lesson-content img {
  border-radius: 1rem;
}

.lesson-content iframe {
  width: 100%;
}

.course-sidebar {
  display: flex;
  justify-content: flex-start;
  width: 20vw !important;
  max-width: 20vw !important;
  min-width: 20vw !important;
}

.sidebar-header {
  display: flex;
  flex-direction: row;
  padding-bottom: 1rem;
  border-bottom: 1px solid #888888;
  align-self: baseline;
  width: 18rem;
  margin-top: 1.8rem;
  margin-left: 1rem;
  text-align: left;
}

.section-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
}

.lesson-outline{
  font-size: 1em;
}

.course-prog{
  font-size: 1.6em;
}

.section-listing {
  color: #888;
  text-align: left;
  font-size: 1em;
}

.current-section-listing {
  color: #006080;
}

.previous-course-button {
  background-color: #eee;
  padding: 0.5rem;
  border-radius: 0.5rem;
  text-align: center;
  border: none;
  margin: 1rem;
  cursor: pointer;
}

.next-course-button {
  margin: 1rem;
  background-color: #006080;
  color: white;
  padding: 0.5rem;
  border-radius: 0.5rem;
  text-align: center;
  border: none;
  cursor: pointer;
}
