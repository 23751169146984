body{
  font-family: sans-serif !important;
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
}

.app {
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.top-nav {
  width: 100%;
  height: 4rem;
  min-height: 4rem;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #E8E8E8
}

.sidebar-brand{
  height: 7vh;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #E8E8E8 !important;
}

#top-nav-logo-container{
  width: 20vw;
  padding: 0 1.5rem;
  display: flex;
  justify-content: flex-start;
}

.top-nav-logo {
  cursor: pointer;
  width: 20vw;
  max-width: 180px;
}

#topnav-title{
  font-size: 1.2em;
  margin-left: 1%;
}


#invite-btn{
	display: flex;
	background-color: #006080;
	color: white;
	width: 25%;
	height: 60%;
  max-height: 50px;
	min-width: 140px;
	border: none;
	padding: 5px;
	font-size: .95em;
	align-items: center;
	justify-content: space-around;
	margin-right: 10px;
	border-radius: 8px;

}
#invite-btn-inner{
  justify-content: center;
  width: 100%;
  display: flex;
  align-items: center;
}

#send-invite-error{
  color: red;
}

#send-invite-success{
  color: green;
}

.invite-icon{
	width: 12%;
	height: 50%;
	filter: invert(100%);
	margin-right: 3px;
}

.invite-icon div{
  font-size: medium;
}

.top-nav h1 {
  position: absolute;
  left: 20vw;
  font-size: 1.2em;
}

#top-left-nav{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 30%;
  height: 100%;
}

.user-info {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  cursor: pointer;
  height: 100%;
}

.user-menu {
  background-color: white;
  width: 12rem;
  box-shadow: 0px 5px 5px 2px rgba(204, 204, 204, 1);
  margin-top: -0.5rem;
}

.user-menu-item {
  width: calc(100% - 2rem);
  padding: 1rem;
  background-color: white;
  cursor: pointer;
}

.user-menu-item:hover {
  background-color: #f2f2f2;
}

.user-caret {
  font-size: xx-large;
  align-self: center;
}

.page {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  height: 100vh;
}

.sidebar {
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.avatar {
  border-radius: 50%;
}

.user-avatar {
  height: 60%;
}

.bold {
  font-weight: bold;
}

.cursor-pointer {
  cursor: pointer;
}

.disabled-lesson{
  color: #555;
  cursor: not-allowed;
}

.modal-title{
  font-size: large;
  padding: 0;
  margin: 0;
}

.modal-backdrop{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background:#08070792;
  z-index: 1;

}

.modal-body{
  text-align: left;
}

.modal-header{
  display: flex;
  justify-content: space-between;
  line-height: 1.5;
}

.modal .close{
  border: none;
  background-color: transparent;
  color: gray;
  font-size: 1.3125rem;
  font-weight: 600;
}

#email-to-invite{
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  padding-right: 5px;
}

.modal{
  border-radius: 0.3rem;
  font-family: 'Montserrat', sans-serif;
  position: absolute;
  top: 2vh;
  max-width: 600px;
  margin: 1.75rem auto;
  left: 50vw;
  transform: translate(-50%, 0);
  background-color: white;
  padding: 2%;
  z-index: 1;
}

#inviteModalLabel{
  color: #006080;
  font-weight: 600;
}

#invite-modal p, .form-check-label{
  color: gray;
  font-size: 0.875rem;
}

.form-check-label{
  line-height: 1.5;
}

#send-invite-btn, #manage-team, #return-courses{
  background-color: #006080;
  color: white;
  border-radius: 4px;
  border: none;
  margin: 10px;
  padding: 10px;
  font-size: 1rem;
}

#invite-email-container{
  margin-bottom: .5rem;
}

#invite-email-input{
  width: 50%;
}

#invite-btns{
display: flex;
width: 100%;
justify-content: space-between;
}

#user-type{
font-weight: 600;
margin-bottom: 15px;
margin-top: 0;
font-size: .9em;
}

#invite-form{
width: 100%;
}

#manage-team{
	font-style: none;
	text-decoration: none;
	background-color: white;
	color: #006080;
	border: 1px solid #006080; 
}


#manage-team:hover{
	background-color: #dfe4e5;
}

#return-courses{
  opacity: 0.9;
}

#return-courses:hover{
  opacity: 1;
}