body > nav {
  height: 100%;
  background-color: var(--white);
}

.left-navbar ul .link-item {
  font-size: medium;
  display: flex;
  flex-direction: row;
  color: var(--black);
  text-decoration: none;
  font-weight: 400;
}

.left-nav-options {
  width: 20vw;
  height: calc(100% - 70px);
  border-right: 1px solid #e8e8e8;
  padding-left: 1rem;
  padding-top: 1.8rem;
  display: flex;
  flex-direction: column;
  text-align: left;
  display: flex;
  justify-content: space-between;
}

.left-nav-footer .leftnav-icon{
	filter: invert(60%);
}

.leftnav-icon{
	padding-right: 8px;
	width: 18px;
	height: 20px;
}

.main-left-nav-width {
  width: 17vw;
  height: 100%;
}

.left-nav-options li {
  color: black;
}

.left-nav-options .coming-soon {
  margin-bottom: 0rem;
  margin-top: 0.25rem;
  color: #7d7c7c;
  padding-left: 0.3rem;
  font-size: x-small;
  padding-right: 2rem;
}

.left-nav-options div {
  color: black;
}

.organization-container {
  display: flex;
  padding-bottom: 1rem;
  width: calc(100% - 2rem);
  justify-content: flex-start;
}

.organization-image {
  width: 3.5rem;
  height: 3.5rem;
  padding: 0 .75em;
}

.organization-container .organization-info {
  font-size: small;
  padding-left: 0.5rem;
}

.organization-container .organization-name {
  font-weight: bold;
  color: #2e2e2e;
  overflow-wrap: break-word;
  text-shadow: 0px 0.4px, 0.4px 0px, 0.4px 0.4px;
}

.left-nav-options ul {
  font-size: .9rem;
}

.left-nav-footer {
  margin-top: auto;
  margin-right: 1rem;
  border-top: 1px solid #b3afaf;
}

.left-nav-footer li .footer-item {
  color: #8f8f8f;
  font-size: smaller;
}

.left-nav-options ul li {
  padding: 0.3rem 0rem 0.3rem 0rem;
}

.popup-container {
  display: none;
  border: 1px solid #006080;
  position: absolute;
  height: 19rem;
  width: 18rem;
  z-index: 5;
  background: white;
  border-radius: 5%;
}

.coming-soon-container:hover .popup-container {
  display: block !important;
}

.bolding{
	font-weight: 600;
    text-shadow: 0px 0.2px, 0.2px 0px, 0.2px 0.2px;
}

.popup-container:hover {
  display: block !important;
}

.popup-container img {
  margin-top: 5%;
  margin-left: 5%;
  margin-bottom: 3%;
  height: 55%;
  width: 90%;
}

.small-left-padding {
  left: 5rem;
}

.big-left-padding {
  left: 7rem;
}

.popup-text {
  font-style: normal;
  font-weight: 200;
  margin-left: 5%;
  font-size: larger;
}

.popup-container button {
  margin-left: 5%;
  margin-top: 2%;
  font-size: larger;
  color: white;
  background-color: #006080;
  border: none;
  border-radius: 5px;
  padding: 2% 3% 2% 3%;
}

/* prevent left-nav from looking squishy when there isn't enough width */
@media (max-width: 800px) {
  .organization-container div {
    transition: 0.2s ease;
    font-size: xx-small;
  }

  .organization-image {
    width: 2.5rem;
    height: 2.5rem;
  }

  .left-nav-options ul {
    transition: 0.2s ease;
  }

  .coming-soon{
		margin-top: .15rem !important;
		font-size: xx-small !important;
	}

	.link-item{
		font-size: x-small !important;
	}
	
	.left-nav-section{
		font-size: small;
	}

	.footer-item{
		font-size:x-small !important;
	}
}

.notify-div {
  display: flex;
  flex-direction: row;
}

.notify-div button{
  margin-bottom: .1rem;
}

.notify-text {
  margin-top: 0.7rem;
  margin-left: 0.5rem;
  margin-bottom: 0;
}

.hover-padding {
  left: 0;
  position: absolute;
  width: 7rem;
  height: 1rem;
}

.hover-padding .popup-container:hover {
  display: block !important;
}

.left-nav-section {
  color: grey !important;
  font-size: 1.3em;
  font-weight: bold;
}

.left-nav-options .coming-soon {
  /* padding-bottom: 1rem; */
  margin-bottom: 0rem;
  margin-top: 0.25rem;
  color: #7d7c7c;
  padding-left: 0.3rem;
  font-size: xx-small;
  padding-right: 2rem;
}

ul {
  list-style-type: none;
  padding: 0px;
}
