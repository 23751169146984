.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f0f0f0;
  overflow-y: scroll;
}

.content-header {
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1rem;
  font-size: large;
  color: #006080;
  font-weight: bold;
  margin-left: 2rem;
  margin-top: 0.5rem;
}

.body {
  padding: 2%;
  width: 96%;
  max-width: 100%;
  height: calc(100vh - 4rem);
  flex-direction: row;
  align-items: flex-start;
  display: flex;
}

.footer {
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fixed-card{
  height: 390px;
  min-height: 340px;
}

.dynamic-card{
  height: fit-content;
}

.course-card {
  background-color: white;
  width: 400px;
  margin: .5rem 2rem;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  border-radius: 0.5rem;
  text-align: left;
  gap: 0.5rem;
}

.preview-card img{
  width: 100%;
  height: 12vw;
  border-radius: 10px;
}

.preview-card{
  width: 20vw;
  margin: 1.5%;
  background-color: white;
  border-radius: 10px;
  padding: 2% 1%;
  text-align: left;
  box-shadow: 0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.2);
}

.preview-card h5{
  font-size: 1.1em;
  margin: 0.75rem 0 !important;
}

.course-card-title {
  font-weight: bold;
}

.course-card-description {
  flex-grow: 1;
  line-height: 1.2;
}

.course-image {
  width: 100%;
  border-radius: 0.5rem;
  height: 230px;
}


.course-lesson-count {
  color: #006080;
}

.course-progress-pill {
  padding: 0.5rem 1rem;
  text-align: center;
  font-family: Arial;
  font-size: 13.3333px;

}

.course-progress-complete{
  background-color: green;

  
}
.course-progress-in-progress{
  background-color: blue;

}

.course-progress-not-started{
  background-color: rgb(162, 162, 162);

}
.course-start-button {
  background-color: #006080;
  color: white;
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
  text-align: center;
  border: none;
}

.course-card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.preview-link{
  text-decoration: none;
}

#complete-course-container{
  background-color: white;
  text-align: left;
  padding: 2%;
  margin: 1% 2%;
  border: 1px solid #006080;
  border-radius: 15px;
  box-shadow: 0px 2px 5px 0px rgb(114, 114, 114);

}

#complete-course-container h2, #complete-course-container h3{
  color: #006080;
}

#celebrate-networks{
  font-weight: 800;
}

#complete-course-container button{
  background-color: #006080;
  color: white;
  padding: 1.35% 2%;
  border: none;
  border-radius: 4px;
  font-weight: 900;
}

#practice-cards, #learn-cards{
  display: flex;
  width: 100%;
  justify-content: flex-start;
}

#practice-header, #learn-header{
  text-align: left;
  color: #006080;
  font-size: 1.4em;
  font-weight: 700;
  margin-bottom: 0;
  margin-left: 2%;
}

.body {
  flex-wrap: wrap;
  justify-content: flex-start;
}